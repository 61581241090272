import React from "react";
import styled from "styled-components";

import { HalfColumnWidthWrapper } from "../components/confines-wrappers";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import Layout from "../components/layout";
import { fontSizeHelper } from "../theme/theme-helper";

const NotFoundWrapper = styled(HalfColumnWidthWrapper)`
  transition: 0.2s ease-in-out;
  padding-top: 6.4rem;

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 68vh;

    p {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  }

  @media (min-width: 568px) {
    .content-container {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 8.2rem;
  }

  @media (min-width: 960px) {
    .content-container {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <>
        <HelmetWrapper
          title="404 Not found"
          description="Opps. Your page does not exist... The sadness... The pain... The agony!"
          canonicalPath="/404"
          robots="noindex, nofollow"
        />

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme="light"
          />
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Not found (404)
          </h1>

          <NotFoundWrapper className="not-found-wrapper">
            <div className="content-container">
              <p>
                <strong>
                  Oops.
                </strong>

                {" "}
                Your page does not exist.

                <br />

                <strong>
                  The sadness... The pain... The agony!
                </strong>
              </p>
            </div>
          </NotFoundWrapper>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default NotFoundPage;
